<template>
	<div class="flexPage themeColor">
		<div class="flexHead">
			<navbar :params="pageInfo" />
		</div>
		<div class="flexContent">
			<nodata v-if="!listNode || listNode.length == 0" />
			<van-list v-model="loading" :finished="finished" :finished-text="$t('other.translate172')"  :loading-text="$t('other.translate173')" @load="onLoad" v-else>
				<div class="orderListBoxs" v-for="item in listNode">
					<div class="order">
						<span>{{ $t('other.translate45') }}: {{ item.id }}</span>
						<div class="statusNode" v-if="item.state == 'COMPLETE'">{{ $t('other.translate65') }}</div>
						<div class="statusNodeF" v-if="item.state == 'DEFEATED'">{{ $t('other.translate66') }}</div>
						<div class="statusNodeW" v-if="item.state == 'APPLYING'">{{ $t('other.translate67') }}</div>
					</div>
					<div style="height: 90px;display: flex;flex-wrap: wrap;align-content: space-between">
						<div class="orders">
							<span>{{ $t('other.translate68') }}: </span>
							<span>{{ processAddress(item.address) }}</span>
							<img src="../../assets/img/copy.png" @click="$mts.copy(item.address)" alt=""
								style="width: 24px;height: 24px;margin-left: 5px;">
						</div>
						<div class="orders">
							<span>{{ $t('other.translate69') }}: </span>
							<span>{{ item.coin.name }}</span>
						</div>
						<div class="orders">
							<span>{{ $t('other.translate53') }}: </span>
							<span>{{ item.amount }}</span>
							<img src="../../assets/img/copy.png" @click="$mts.copy(item.amount.toString())" alt=""
								style="width: 24px;height: 24px;margin-left: 5px;">
						</div>
						<div class="orders">
							<span>{{ $t('other.translate70') }}: </span>
							<span>{{ item.creationTime }}</span>
						</div>
					</div>
				</div>
			</van-list>
			<!-- <div class="center seeMore">查看更多</div> -->
		</div>
	</div>
</template>
<script>
import navbar from '@/views/modules/navbar.vue'
export default {
	data() {
		return {
			pageInfo: {
				title: this.$t('form.assets.cbResult'),
				navbarStyle: 'normal'
			},
			typeActive: 0,
			tabs: [
				{ title: this.$t('trade.all'), active: 'active' },
				{ title: this.$t('otc.order.pending'), active: '' },
				{ title: this.$t('otc.order.completed'), active: '' }
			],
			pageNum: 1, // 当前页码
			submiting: false,
			listNode: [],
			loading: false,
			finished: false,
			total: 0
		}
	},
	components: { navbar },
	watch: {},
	computed: {
		list() {
			return this.$store.state.app.vanList;
		},
		finished() {
			return this.$store.state.app.finished;
		}
	},
	beforeDestroy() {
		this.$mts.initPage();
	},
	mounted() {
		let that = this;
		this.query()
	},
	methods: {
		// 截取字符串
		processAddress(address) {
			if (address.length < 23) {
				return address;
			}
			var prefix = address.substring(0, 19);
			var suffix = address.substring(address.length - 4);
			return prefix + "..." + suffix;
		},
		
		onChooseTab(index) {
			this.typeActive = index;
			this.refresh();
		},
		refresh() {
			this.pageNum = 0;
			this.$mts.initPage();
			this.$store.state.app.loading = true;
			
		},
		query() {
			this.loading = false
			this.$http.get(this.host + `/uc/legal-wallet-recharge?pageNo=${this.pageNum}&pageSize=10`).then(res => {
				console.log("p0", res);
				if (this.listNode) {
					this.listNode.push(...res.body.data.list)
				} else {
					this.listNode = res.body.data.list
				}
				this.total = res.body.data.totalCount
			});
		},
		onLoad() {
			// 异步更新数据
			setTimeout(() => {
				if (this.listNode.length >= this.total) {
					this.finished = true
					return
				}
				this.pageNum++
				this.query()
			}, 1000)
		},
		formatStatus(status) {
			switch (status) {
				case "CONFIRM":   //已到账
					return this.$t('otc.order.completed');
					break;
				default:
					return this.$t('otc.order.pending');
					break;
			}
		},
	}
}
</script>
<style scoped>
.statusNodeW {
	width: auto;
	min-width: 57px;
	height: 23px;
	padding: 0 5px;
	background: #0052FF33;
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	line-height: 23px;
	text-align: center;
	border-radius: 4px;
	color: #0052FF;

}

.statusNodeF {
	width: auto;
	min-width: 57px;
	height: 23px;
	padding: 0 5px;
	background: #EB433533;
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	line-height: 23px;
	text-align: center;
	border-radius: 4px;
	color: #EB4335;

}

.statusNode {
	width: auto;
	min-width: 57px;
	height: 23px;
	padding: 0 5px;
	background: #3AD29F33;
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	line-height: 23px;
	text-align: center;
	border-radius: 4px;
	color: #3AD29F;

}

.orders span:first-child {
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	color: #00000080;
	margin-right: 8px
}

.orders span:last-child {
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	color: #000;

}

.orders {
	width: 100%;
	display: flex;
	align-items: center
}

.order {
	width: 100%;
	font-family: Inter;
	font-size: 14px;
	font-weight: 600;
	text-align: left;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.orderListBoxs {
	width: 100%;
	padding: 0 16px;
	margin-top: 17px;
	border-bottom: 1px solid #0000001A;
	padding-bottom: 16px
}
</style>